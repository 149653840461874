
import Favorite from '@/assets/ui/icons/Favorite.svg'
import Courses from '@/assets/ui/icons/Courses.svg'
import Bell from '@/assets/ui/icons/Bell.svg'
import Orders from '@/assets/ui/icons/Orders.svg'
import Comments from '@/assets/ui/icons/Comments.svg'
import Bag from '@/assets/ui/icons/Bag.svg'
import Read from '@/assets/ui/icons/Read.svg'
import Communicate from '@/assets/ui/icons/Communicate.svg'
import Study from '@/assets/ui/icons/Study.svg'
import Do from '@/assets/ui/icons/Do.svg'
import AboutUs from '@/assets/ui/icons/AboutUs.svg'
import Coop from '@/assets/ui/icons/Coop.svg'
import Docs from '@/assets/ui/icons/Docs.svg'
import Faq from '@/assets/ui/icons/Faq.svg'
import Report from '@/assets/ui/icons/Report.svg'
import Buy from '@/assets/ui/icons/Buy.svg'
import Upcycle from '@/assets/ui/icons/Upcycle.svg'
import Family from '@/assets/ui/icons/Family.svg'
import Physio from '@/assets/ui/icons/Physio.svg'
import Consent from '@/assets/ui/icons/Consent.svg'
import Partners from '@/assets/ui/icons/Partners.svg'
import Intimacy from '@/assets/ui/icons/Intimacy.svg'

export const icons = {
  Favorite,
Courses,
Bell,
Orders,
Comments,
Bag,
Read,
Communicate,
Study,
Do,
AboutUs,
Coop,
Docs,
Faq,
Report,
Buy,
Upcycle,
Family,
Physio,
Consent,
Partners,
Intimacy,
}

export type IIcon = keyof typeof icons

export const useIcons = (icon: IIcon) => {
  const Icon = icons[icon]
  return {
    Icon
  }
}

<script setup lang="ts">
import type { IIcon } from '~/composables/useIcons'
import { useIcons } from '#imports'

interface Props {
  icon: IIcon,
  isCategoryIcon?: boolean
}
const props = withDefaults(defineProps<Props>(),{
  isCategoryIcon: false
})

const icon = computed(() => useIcons(props.icon).Icon)
</script>

<template lang="pug">
.icon(:class="{ 'isCategoryIcon': props.isCategoryIcon }")
  component(:is="icon")
</template>

<style lang="scss" scoped>
.icon {
  width: get-vw(40);
  height: get-vw(40);
  &:deep(svg [clip-path]) {
    clip-path: none;
  }
  &.isCategoryIcon {
    max-width: get-vw(56);
    max-height: get-vw(56);
    width: auto;
    height: 100%;
  }

  @include tablet {
    width: get-vw(24, "md");
    height: get-vw(24, "md");
  }

  @include mobile {
    width: get-vw(24, "sm");
    height: get-vw(24, "sm");

    &.isCategoryIcon {
      max-width: get-vw(24, "sm");
      max-height: get-vw(24, "sm");
    }
  }
}
</style>
